<template>
	<div class="forgetPw app_flex">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="忘记密码" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div class="forgetPw_body mt34 app_scroll">
			<van-form class="forgetPw_form" submit-on-enter label-width="2.5866rem" @submit="fSubmit">
				<van-field v-model="oForm.phone" type="phone" name="phone" label="手机号" placeholder="请输入手机号"
					maxlength="11" input-align="right" :border="false" autocomplete
					:rules="[{ validator:rules.phone.validator }]" />
				<van-field v-model="oForm.code" type="number" name="code" label="验证码" placeholder="请输入验证码"
					maxlength="6" input-align="right" :border="false" autocomplete :rules="[{ validator:rules.code.validator }]">
					
					<template #button>
						<span v-if="oCode.isShowBtn" class="forgetPw_code" @click="fGetCode">{{oCode.btnText}}</span>
						<span v-else class="forgetPw_code">{{fSecond(current.total)}}s</span>
					</template>
					
				</van-field>
				<van-field v-model="oForm.password" type="password" name="password" label="输入新密码"
					placeholder="请输入6~16位密码" maxlength="16" input-align="right" :border="false" autocomplete
					:rules="[{ validator:rules.password.validator }]" />
				<van-field v-model="oForm.passwordSame" type="password" name="passwordSame" label="确认新密码"
					placeholder="请再次输入6~16位密码" maxlength="16" input-align="right" :border="false" autocomplete
					:rules="[{ validator:rules.passwordSame.validator }]" />
					
				<div class="forgetPw_bottom">
					<van-button round block native-type="submit" class="app_button" :disabled="oLoginBtn.isDisabled">
						确认
					</van-button>
				</div>
			</van-form>
		</div>

	</div>
</template>

<script>
	import {
		reactive,
		inject,
		onActivated,
	} from "vue"
	import {
		useStore
	} from "vuex"
	import {
		useRoute,
		useRouter
	} from "vue-router"
	import {
		Toast
	} from 'vant'
	import {
		useCountDown
	} from '@vant/use'
	import {
		apiGetCode
	} from '@/api/useInfo.js'
	import {
		fnSecond,
		fnKeyIsTrue
	} from "@/utils/public.js"
	import {
		fnValidStrLength,
		fnIsEN
	} from '@/utils/validate.js'
	import tipsContent from '@/utils/tipsContent'
	export default {
		name: "ForgetPw",
		setup() {
			const route = useRoute(); //初始化路由
			const router = useRouter(); //初始化路由
			const fnGlobalReload = inject("fGlobalReload");
			const store = useStore(); // 使用useStore方法
			const fSecond = fnSecond; //转化秒为单位
			
			// 规则
			const rules = {
				phone: {
					validator: (val) => {
						if (/1\d{10}/.test(val)) {
							oLoginBtn.isTrue.phone = true;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return true
						} else {
							oLoginBtn.isTrue.phone = false;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return false
						}
					}
				},
				code: {
					validator: (val) => {
						if (val.length) {
							oLoginBtn.isTrue.code = true;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return true
						} else {
							oLoginBtn.isTrue.code = false;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return false
						}
					}
				},
				password: {
					validator: (val) => {
						if (val.length >= 6) {
							oLoginBtn.isTrue.password = true;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return true
						} else {
							oLoginBtn.isTrue.password = false;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return false
						}
					}
				},
				passwordSame: {
					validator: (val) => {
						if (val.length >= 6) {
							oLoginBtn.isTrue.passwordSame = true;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return true
						} else {
							oLoginBtn.isTrue.passwordSame = false;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return false
						}
					}
				}
			};
			
			// 登录按钮包含属性
			const oLoginBtn = reactive({
				// 所有验证全部通过
				isTrue: {
					phone: false,
					code: false,
					password: false,
					passwordSame: false,
				},
				// 是否禁用
				isDisabled: true
			});
			
			//表单字段 
			const oForm = reactive({
				phone: null,
				code: null,
				password: null,
				passwordSame: null,
			});
			// 发送验证码相关对象
			const oCode = reactive({
				isShowBtn: true, //是否展示发送验证码按钮
				btnText: "获取验证码", //发送验证码按钮-文字展示
				isDisabled: false, //是否禁用发送验证码按钮
			});
			// 倒计时
			const countDown = useCountDown({
				// 倒计时 1分钟
				time: 60 * 1000,
				onFinish: () => {
					oCode.btnText = "重新获取";
					oCode.isShowBtn = true;
				}
			});
			// 获取验证码
			const fGetCode = () => {
				// 手机号为空验证
				if (!oForm.phone) {
					return Toast({
						message: tipsContent.emptyPhone,
						position: 'bottom'
					});
				}
				// 手机号规则验证
				if (!oLoginBtn.isTrue.phone) {
					return Toast({
						message: tipsContent.formatPhone,
						position: 'bottom'
					});
				}
				apiGetCode({
					phoneNumber: oForm.phone,
					smsType:"2"
				}).then((res) => {
					console.log(res)
					countDown.reset();
					countDown.start();
					oCode.isShowBtn = false;
					oCode.isDisabled = true;
					Toast({
						message: tipsContent.sentMessage,
						position: 'bottom'
					});
				}).catch(() => {
					oCode.isShowBtn = true;
					oCode.isDisabled = false;
				})
			};
			
			
			// 表单提交触发事件
			const fSubmit = () => {
				// 密码规则验证
				if (!fnValidStrLength(oForm.password)) {
					return Toast({
						message: tipsContent.formatPassword,
						position: 'bottom'
					});
				} else if (!fnIsEN(oForm.password)) {
					return Toast({
						message: tipsContent.formatPassword,
						position: 'bottom'
					});
				}
				// 确认密码规则验证
				if (!fnValidStrLength(oForm.passwordSame)) {
					return Toast({
						message: tipsContent.formatPassword,
						position: 'bottom'
					});
				} else if (!fnIsEN(oForm.passwordSame)) {
					return Toast({
						message: tipsContent.formatPassword,
						position: 'bottom'
					});
				}
				// 两次密码是否一致
				if (oForm.password != oForm.passwordSame) {
					return Toast({
						message: tipsContent.diffPassword,
						position: 'bottom'
					});
				}
				Toast({
					type: "loading"
				});
				store.dispatch("userInfo/ForgetPw", {
					accountNo: oForm.phone,
					smsCode: oForm.code,
					newPw: oForm.password,
					confirmNewPw: oForm.passwordSame,
					userRegisterType: 2
				}).then(() => {
					Toast({
						message: tipsContent.success,
						position: 'bottom'
					});
					router.push({
						path: "/loginPw"
					});
				})
			};

			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				router.push({
					path: '/loginPw'
				})
			};

			
			onActivated(() => {
				const route_meta = route.meta;
				if (route_meta.resetType === '1') {
					route_meta.resetType = '0';
					fnGlobalReload();
				} else if (route_meta.resetType === '0') {
					route_meta.resetType = '1';
				}
			});
			return {
				rules,
				oLoginBtn,
				oForm,
				oCode,
				
				fGetCode,
				current: countDown.current,
				fSubmit,
				fLeft,
				
				fSecond
			};
		}
	}
</script>

<style scoped lang="scss">
	.forgetPw {
		width: 100%;
		height: 100%;
		background: #F1F2F7;

		.forgetPw_body {
			position: relative;
			
			padding: 0 0.32rem 0;
			.forgetPw_code {
				font-size: 0.3733rem;
				color: #FF7671;
			}
			
			.forgetPw_bottom{
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				padding: 0.4266rem;
				background: #FFFFFF;
				border-radius: 0.2133rem 0.2133rem 0px 0px;
			}
		}
	}

	
</style>
