<template>
	<div class="app_page login app_flex">
		<div class="login_header">
			<div class="login_header_flex">
				<img  src="~@/assets/images/project/yiman_001.png" class="login_img_002">
				<div class="login_header_flex_b">
<!--					<span>你好,</span>-->
					<span>欢迎你</span>
				</div>
			</div>
		</div>

		<div class="login_body app_scroll">
			<van-form class="login_form" validate-trigger="onChange" @submit="fSubmit">
				<p class="login_label">手机号</p>
				<van-field v-model="oForm.phone" type="phone" name="phone" placeholder="请输入手机号" maxlength="11"
					:rules="[{ validator:rules.phone.validator }]" />
				<p class="login_label">密码</p>
				<van-field v-model="oForm.password" type="password" name="password" placeholder="请输入密码" maxlength="16"
					autocomplete :rules="[{ validator:rules.password.validator }]">
					<template #button>
						<span class="login_forget" @click="fForget">忘记密码</span>
					</template>
				</van-field>
				<div class="mt65">
					<van-button round block native-type="submit" class="app_button" :disabled="oLoginBtn.isDisabled">
						登录
					</van-button>
				</div>
			</van-form>

			<div class="login_ways">
				<span @click="fLoginOfCode">验证码登录</span>
				<span @click="fRegister">注册账号</span>
			</div>

			<van-checkbox class="mt16 login_checkbox_text" v-model="oForm.isAgree" checked-color="#FF7671"
				label-disabled>
				我已阅读并同意<span class="app_agreement" @click="fnGoGeneralTxt('user')">《用户协议》</span><span
					class="app_agreement" @click="fnGoGeneralTxt('privacy')">《隐私协议》</span><span
					class="app_agreement" @click="fnGoGeneralTxt('synchronization')">《用户信息同步协议》</span>
			</van-checkbox>
		</div>
	</div>
</template>

<script>
	import {
		reactive,
		inject,
		onActivated,
	} from "vue"
	import {
		useStore
	} from "vuex"
	//引入路由函数
	import {
		useRoute,
		useRouter
	} from "vue-router"
	import {
		Toast
	} from 'vant'
	import {
		fnKeyIsTrue
	} from "@/utils/public.js"
	import {
		fnGoGeneralTxt
	} from "@/utils/router.js"
	import config from "@/utils/config.js"
	import tipsContent from '@/utils/tipsContent'
	export default {
		name: "LoginPw",
		setup() {
			const route = useRoute(); //初始化路由
			const router = useRouter(); //初始化路由
			const fnGlobalReload = inject("fGlobalReload");
			const store = useStore(); // 使用useStore方法

			// 登录按钮包含属性
			const oLoginBtn = reactive({
				// 所有验证全部通过
				isTrue: {
					phone: false,
					password: false
				},
				// 是否禁用
				isDisabled: true
			});

			// 规则
			const rules = {
				phone: {
					validator: (val) => {
						console.log(val)
						if (/1\d{10}/.test(val)) {
							oLoginBtn.isTrue.phone = true;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return true
						} else {
							oLoginBtn.isTrue.phone = false;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return false
						}
					}
				},
				password: {
					validator: (val) => {
						if (val.length >= 6) {
							oLoginBtn.isTrue.password = true;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return true
						} else {
							oLoginBtn.isTrue.password = false;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return false
						}
					}
				}
			};
			//表单字段
			const oForm = reactive({
				isAgree: false,
				phone: null,
				password: null,
			});
			// 表单提交触发事件
			const fSubmit = (values) => {
				if (!oForm.isAgree) {
					return Toast({
						message: tipsContent.disagreeRead + "《用户协议》、《隐私协议》和《用户信息同步协议》哦",
						position: 'bottom'
					});
				}

				Toast({
					type: "loading"
				});
				store.dispatch("userInfo/Login", {
					accountNo: values.phone,
					certificate: values.password,
				}).then(res => {
					console.log(res);
					router.push({
						path: "/"
					});
				}).catch(err => {
					console.log(err);
				})
			};

			// 忘记密码
			const fForget = () => {
				router.push({
					path: '/forgetPw'
				})
			};
			// 验证码登录
			const fLoginOfCode = () => {
				router.push({
					path: '/loginCode'
				})
			};
			// 注册账号
			const fRegister = () => {
				router.push({
					path: '/register'
				})
			};

			// 生命周期
			onActivated(() => {
				const route_meta = route.meta;
				if (route_meta.resetType === '1') {
					route_meta.resetType = '0';
					fnGlobalReload();
				} else if (route_meta.resetType === '0') {
					route_meta.resetType = '1';
				}
			});
			return {
				rules,
				oLoginBtn,
				oForm,

				fSubmit,
				fForget,
				fLoginOfCode,
				fRegister,
				fnGoGeneralTxt,

				config
			};
		}
	}
</script>

<style scoped lang="scss">
	.login_header {
		width: 100%;
		height: 6.56rem;
		padding: 1.9466rem 0.72rem;
		box-sizing: border-box;
		background-image: url('~@/assets/images/project/img_002.png');
		background-size: 100%;

		.login_header_flex {
			display: flex;
			align-items: center;

			.login_img_002 {
				width: 1.5733rem;
				margin-right: 0.32rem;
			}

			.login_header_flex_b {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				font-size: 0.6933rem;
				font-weight: 600;
				color: #FFFFFF;
				line-height: 0.8533rem;
			}
		}
	}

	.login_body {
		padding: 1.28rem 0.72rem 0;
		margin-top: -2.2666rem;
		background: #FFFFFF;
		border-radius: 0.4266rem 0.4266rem 0px 0px;
	}

	.login_forget {
		font-size: 0.3733rem;
		color: #979797;
	}

	.login_ways {
		margin-top: 0.6933rem;
		display: flex;
		align-items: center;
		justify-content: center;

		&>span {
			padding: 0 0.2666rem;
			color: #FF7671;
		}

		&>span:not(:first-child) {
			border-left: 0.0266rem solid #FF7671;
		}
	}

	.login_checkbox_text {
		padding: 0 0.3466rem;
	}
</style>
