<template>
	<div class="bindShop">
		<van-sticky>
			<van-nav-bar title="绑定彩票店" left-arrow @click-left="fLeft" />
		</van-sticky>
		<div class="h5bgF5F5F5"></div>
		<div class="bindShop_li" v-for="(item,index) in aListData" :key="index">
			<van-cell class="bindShop_li_first" center :title="item.name" is-link
				:arrow-direction="item.shopShow ? 'up' : 'down'" value="" @click="fShopShow(index)" />
			<div class="h5bgF5F5F5"></div>
			<van-cell class="bindShop_li_third" v-show="item.shopShow"
				v-for="(itemA,indexA) in item.lotteryShopResponses" :key="indexA" center title="单元格" value=""
				@click="fSelectShop(index,indexA,itemA.shopId)">
				<template #title>
					<p class="bindShop_li_third_shopName">{{itemA.shopName}}</p>
					<!-- <p class="bindShop_li_third_shopAddress">浙江省杭州市余杭塘路88号</p> -->
				</template>
				<template #right-icon>
					<!-- <img src="~@/assets/images/radioOff.png" alt="" class="bindShop_li_third_right_img"
						v-show="!itemA.select">
					<img src="@/assets/images/radioOn.png" alt="" class="bindShop_li_third_right_img"
						v-show="itemA.select"> -->
				</template>
			</van-cell>
			<div class="h10bgF5F5F5"></div>
		</div>
		<span class="bindShop_prompt">
			<em class="cE73243">*</em>各省彩票店正在陆续建设完善中
		</span>
		<van-button class="app_button fixedB0" block native-type="submit" :disabled="!oSelectShop.select" @click="fChoose">
			提交
		</van-button>
	</div>
</template>

<script>
	import {
		ref,
		reactive
	} from "vue"
	import {
		useRoute,
		useRouter
	} from "vue-router"
	import {
		useStore
	} from "vuex"
	import {
		Toast
	} from 'vant'
	import {
		apiLotteryShopList,
		apiBindLottery
	} from '@/api/useInfo'
	import tipsContent from '@/utils/tipsContent'
	export default {
		name: "BindShop",
		setup() {
			const route = useRoute(); //初始化路由
			const router = useRouter(); //初始化路由
			const store = useStore(); // 使用useStore方法
			// 列表数据
			const aListData = ref([]);
			// 已选中的店铺
			const oSelectShop = reactive({
				select: false,
				// 省下标
				provinceIndex: "",
				// 店唯一值
				shopId: "",
				// 店下标
				shopIndex: "",
			});
			// 获取城市列表
			const fGetCityList = () => {
				apiLotteryShopList({
					'addressCode': "",
					'parentId': 0
				}).then(res => {
					const { cityResponses } = res.result;
					aListData.value = [...cityResponses];
					if(aListData.value.length > 1){
						fShopShow(0);
					}
				}).catch(() => {})
			};
			fGetCityList();
			
			// 是否展示该分类店铺
			const fShopShow = (index) => {
				const {
					shopShow
				} = aListData.value[index];
				aListData.value[index].shopShow = shopShow ? false : true;
				// 是否存在
				if (!Object.prototype.hasOwnProperty.call(aListData.value[index], "lotteryShopResponses")) {
					fGetShopList(index);
				}
			}
			// 获取彩票店列表
			const fGetShopList = (index) => {
				apiLotteryShopList({
					'addressCode': aListData.value[index]['code']
				}).then(res => {
					console.log("+++++++获取彩票店列表成功++++++++");
					let data = res.result;
					aListData.value[index].lotteryShopResponses = [...data.lotteryShopResponses];
				}).catch(() => {})
			};

			// 选中商店（所属省下标，所属店铺下标，当前商店shopId）
			const fSelectShop = (idxA, idxB, shopId) => {
				console.log(idxA, idxB, shopId);
				console.log(oSelectShop)
				// 找到key对应的店铺
				if (aListData.value[idxA].lotteryShopResponses[idxB].shopId == shopId) {
					// 如果选中记录select为true
					if (oSelectShop['select']) {
						// 选中记录key等于当前选中的key
						if (oSelectShop['shopId'] == shopId) {
							return;
						} else {
							// 如果不等于则清除选中记录对应的值
							let {
								provinceIndex,
								shopIndex
							} = oSelectShop;
							aListData.value[provinceIndex].lotteryShopResponses[shopIndex].select = false;
							// 修改当前选中状态
							aListData.value[idxA].lotteryShopResponses[idxB].select = true;
							console.log(aListData);
							// 保留选中记录
							oSelectShop['select'] = true;
							oSelectShop['shopId'] = shopId;
							oSelectShop['provinceIndex'] = idxA;
							oSelectShop['shopIndex'] = idxB;
						}
					} else {
						aListData.value[idxA].lotteryShopResponses[idxB].select = true;
						// 保留选中记录
						oSelectShop['select'] = true;
						oSelectShop['shopId'] = shopId;
						oSelectShop['provinceIndex'] = idxA;
						oSelectShop['shopIndex'] = idxB;
					}
				}
			};
			
			// 按钮是否可点击
			const disabledSubmit = ref(true);
			// 选择，决定
			const fChoose = () => {
				if(!oSelectShop['select']){
					Toast({
						message: tipsContent.requireShop,
						position: 'bottom'
					});
					return;
				}else{
					disabledSubmit.value = false;
					apiBindLottery({
						"shopId": oSelectShop['shopId'],
						"userId": store.state.userInfo.userInfo.userId
					}).then(() => {
						return store.dispatch("userInfo/GetUserInfo", store.state.userInfo.userInfo.userId)
					}).then(() => {
						// 按钮置灰
						disabledSubmit.value = true;
						const redirect = route.query.redirect ? route.query.redirect : '';
						router.push({
							path: '/' + redirect
						})
					}).catch(() => {
						disabledSubmit.value = false;
					})
				}
			};
			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				router.push({
					path: '/loginPw'
				})
			};
			return {
				aListData,
				oSelectShop,
				fShopShow,
				fSelectShop,
				fChoose,
				fLeft
			}
		}
	}
</script>

<style scoped lang="scss">
	.bindShop_prompt{
		margin: 0 0.4rem;
		font-size: 0.32rem;
		font-weight: 500;
		color: #999999;
		line-height: 0.4266rem;
	}
</style>
