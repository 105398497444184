<template>
	<div class="addRole app_flex" :style="isApp ? 'padding-top: 1.3rem;box-sizing: border-box' : ''">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="新增角色" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div class="addRole_body app_scroll mt12">
			<van-cell-group inset>
				<van-field v-model="form.roleName" label="角色名称" input-align="right" maxlength="8"
					placeholder="请输入2-8个字符" @update:model-value="fChange" @blur="fBlur" />
			</van-cell-group>
		</div>

		<div class="addRole_btn">
			<van-button class="app_button" block native-type="submit" :disabled="form.isDisabled" @click="fSubmit">
				确定
			</van-button>
		</div>
	</div>
</template>

<script>
	import {
		reactive,
		computed
	} from 'vue';
	import {
		useRoute,
		useRouter
	} from 'vue-router';
	import {
		useStore
	} from "vuex"
	import {
		Toast
	} from 'vant'
	import {
		fnValidStrLength
	} from "@/utils/validate.js";
	import {
		uaFun
	} from "@/utils/public.js";
	import {
		apiUserRole
	} from '@/api/useInfo'
	export default {
		name: "AddRole",
		setup() {
			const isApp = uaFun()
			const route = useRoute();
			const router = useRouter();
			const store = useStore();
			const UserInfo = computed(() => store.state.userInfo.userInfo);

			const form = reactive({
				roleName: null,
				isDisabled: true
			});
			// 改变
			const fChange = () => {
				if (!fnValidStrLength(form.roleName, 2, 8)) {
					form.isDisabled = true;
				} else {
					form.isDisabled = false;
				}
			};
			// 失焦
			const fBlur = () => {
				if (!fnValidStrLength(form.roleName, 2, 8)) {
					form.isDisabled = true;
				} else {
					form.isDisabled = false;
				}
			};
			// 提交
			const fSubmit = () => {
				form.isDisabled = true;
				Toast({
					type: "loading"
				});
				apiUserRole({
					isDefault: UserInfo.value.roleId ? 'NO' : 'YES',
					roleName: form.roleName,
					userId: UserInfo.value.userId,
					appTerm:'H5'
				}).then(() => {
					form.isDisabled = false;
					Toast({
						type: "loading"
					});
					return store.dispatch("userInfo/GetUserInfo", UserInfo.value.userId);
				}).then(() => {
					form.isDisabled = false;
					fLeft();
				}).catch(() => {
					form.isDisabled = false;
				})
			};

			const fLeft = () => {
				store.dispatch("other/USRouterFrom", route.fullPath)
				router.back();
			};
			return {
				form,
				fChange,
				fBlur,
				fSubmit,
				isApp,
				fLeft
			}
		}
	}
</script>

<style scoped lang="scss">
	.addRole {
		width: 100%;
		height: 100%;
		background: #F1F2F7;
		box-sizing: border-box;
	}
</style>
