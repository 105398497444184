<template>
	<div class="register app_flex">
		<div class="app_title_a">
			<van-sticky>
				<van-nav-bar title="注册" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div class="register_body app_scroll">
			<van-form class="register_form" >
				<div style="overflow-y: auto">
					<div style="height: 10rem;padding-bottom: 3.5rem">
						<p class="register_label">手机号</p>
						<van-field v-model="oForm.phone" type="tel" name="phone" placeholder="请输入手机号" maxlength="11" :rules="[{ validator:rules.phone.validator }]" />
						<p class="register_label">验证码</p>
						<van-field v-model="oForm.code" type="digit" name="code" placeholder="请输入验证码" maxlength="6" :rules="[{ validator:rules.code.validator }]">
							<template #button>
								<span v-if="oCode.isShowBtn" class="register_code" @click="fGetCode">{{oCode.btnText}}</span>
								<span v-else class="register_code">{{fSecond(current.total)}}s</span>
							</template>
						</van-field>
						<p class="register_label">输入密码</p>
						<van-field v-model="oForm.password" type="password" name="password" placeholder="请输入6~16位密码" maxlength="16" autocomplete :rules="[{ validator:rules.password.validator }]" />
						<p class="register_label">确认密码</p>
						<van-field v-model="oForm.passwordSame" type="password" name="passwordSame" placeholder="请再次输入6~16位密码" maxlength="16" autocomplete :rules="[{ validator:rules.passwordSame.validator }]" />
					</div>
				</div>
			</van-form>
		</div>
		<div class="register_bottom">
			<van-checkbox class="mt16" v-model="oForm.isAgree" checked-color="#FF7671" label-disabled>
				我已阅读并同意<span class="app_agreement" @click="fGoGeneralTxt('user')">《用户协议》</span>
				<span class="app_agreement" @click="fGoGeneralTxt('privacy')">《隐私协议》</span>
				<span class="app_agreement" @click="fGoGeneralTxt('synchronization')">《用户信息同步协议》</span>
			</van-checkbox>

			<div class="mt26">
				<van-button class="app_button" round block native-type="submit"
							@click="fSubmit"
							:disabled="oLoginBtn.isDisabled">
					注册
				</van-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		reactive,
		inject,
		onActivated,
	} from "vue"
	import {
		useStore
	} from "vuex"
	import {
		useRoute,
		useRouter
	} from "vue-router"
	import {
		Toast
	} from 'vant'
	import {
		useCountDown
	} from '@vant/use'
	import {
		apiGetCode
	} from '@/api/useInfo.js'
	import {
		fnSecond,
		fnKeyIsTrue
	} from "@/utils/public.js"
	import {
		fnIsHasHistory,
		fnGoGeneralTxt
	} from "@/utils/router.js"
	import {
		fnValidStrLength,
		fnIsObject,
		fnIsEN
	} from "@/utils/validate.js"
	import tipsContent from "@/utils/tipsContent"
	export default {
		name: "Register",
		setup() {
			const route = useRoute(); //初始化路由
			const router = useRouter(); //初始化路由
			const fnGlobalReload = inject("fGlobalReload");
			const store = useStore(); // 使用useStore方法
			const fSecond = fnSecond; //转化秒为单位
			const fGoGeneralTxt = fnGoGeneralTxt; //跳转通用文本页面

			// 规则
			const rules = {
				phone: {
					validator: (val) => {
						console.log(oLoginBtn)
						if (/1\d{10}/.test(val)) {
							oLoginBtn.isTrue.phone = true;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return true
						} else {
							oLoginBtn.isTrue.phone = false;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return false
						}
					}
				},
				code: {
					validator: (val) => {
						if (val.length) {
							oLoginBtn.isTrue.code = true;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return true
						} else {
							oLoginBtn.isTrue.code = false;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return false
						}
					}
				},
				password: {
					validator: (val) => {
						if (val.length >= 6) {
							oLoginBtn.isTrue.password = true;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return true
						} else {
							oLoginBtn.isTrue.password = false;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return false
						}
					}
				},
				passwordSame: {
					validator: (val) => {
						if (val.length >= 6) {
							oLoginBtn.isTrue.passwordSame = true;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return true
						} else {
							oLoginBtn.isTrue.passwordSame = false;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return false
						}
					}
				}
			};

			// 生命周期
			onActivated(() => {
				const route_meta = route.meta;
				if (route_meta.resetType === '1') {
					route_meta.resetType = '0';
					fnGlobalReload();
				} else if (route_meta.resetType === '0') {
					route_meta.resetType = '1';
				}
			});

			// 登录按钮包含属性
			const oLoginBtn = reactive({
				// 所有验证全部通过
				isTrue: {
					phone: false,
					code: false,
					password: false,
					passwordSame: false
				},
				// 是否禁用
				isDisabled: true
			});

			//表单字段
			const oForm = reactive({
				phone: null,
				code: null,
				password: null,
				passwordSame: null,
				isAgree: false,
			});

			// 发送验证码相关对象
			const oCode = reactive({
				isShowBtn: true, //是否展示发送验证码按钮
				btnText: "获取验证码", //发送验证码按钮-文字展示
				isDisabled: false, //是否禁用发送验证码按钮
			});
			// 倒计时
			const countDown = useCountDown({
				// 倒计时 1分钟
				time: 60 * 1000,
				onFinish: () => {
					oCode.btnText = "重新获取";
					oCode.isShowBtn = true;
				}
			});
			// 获取验证码
			const fGetCode = () => {
				console.log("获取验证码");
				// 手机号为空验证
				if (!oForm.phone) {
					return Toast({
						message: tipsContent.emptyPhone,
						position: 'bottom'
					});
				}
				// 手机号规则验证
				if (!oLoginBtn.isTrue.phone) {
					return Toast({
						message: tipsContent.formatPhone,
						position: 'bottom'
					});
				}
				apiGetCode({
					phone: oForm.phone
				}).then((res) => {
					console.log(res);
					countDown.reset();
					countDown.start();
					oCode.isShowBtn = false;
					oCode.isDisabled = true;
					Toast({
						message: tipsContent.sentMessage,
						position: 'bottom'
					});
				}).catch(() => {
					oCode.isShowBtn = true;
					oCode.isDisabled = false;
				});
			};


			// 表单提交触发事件
			const fSubmit = () => {
				// 密码规则验证
				if (!fnValidStrLength(oForm.password)) {
					return Toast({
						message: tipsContent.formatPassword,
						position: 'bottom'
					});
				} else if (!fnIsEN(oForm.password)) {
					return Toast({
						message: tipsContent.formatPassword,
						position: 'bottom'
					});
				}
				// 确认密码规则验证
				if (!fnValidStrLength(oForm.passwordSame)) {
					return Toast({
						message: tipsContent.formatPassword,
						position: 'bottom'
					});
				} else if (!fnIsEN(oForm.passwordSame)) {
					return Toast({
						message: tipsContent.formatPassword,
						position: 'bottom'
					});
				}
				// 两次密码是否一致
				if (oForm.password != oForm.passwordSame) {
					return Toast({
						message: tipsContent.diffPassword,
						position: 'bottom'
					});
				}
				// 是否同意协议
				if (!oForm.isAgree) {
					return Toast({
						message: tipsContent.disagreeRead + "《用户协议》、《隐私协议》和《用户信息同步协议》哦",
						position: 'bottom'
					});
				}
				Toast({
					type: "loading"
				});
				store.dispatch("userInfo/Register", {
					phone: oForm.phone,
					smsCaptcha: oForm.code,
					password: oForm.password,
					confirmPassword: oForm.passwordSame
				}).then(res => {
					console.log(res);
					let data = res.result;
					router.push({
						path: fnIsObject(data) && data['path'] ? data['path'] : "/loginPw"
						// path:  "/"
					});
					debugger
				}).catch((err) => {
					// 检测是不是对象，是否取path跳转
					if (fnIsObject(err) && err["result"] && err["result"]['path']) {
						router.push({
							path: err["result"]['path']
						});
					}
					// console.log(err);
					// // let data = res.result;
					// router.push({
					// 	// path: fnIsObject(data) && data['path'] ? data['path'] : "/loginPw"
					// 	path:  "/"
					// });

				})
			};


			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/loginPw");
			};

			return {
				rules,
				oLoginBtn,
				oForm,
				oCode,

				fGetCode,
				current: countDown.current,
				fSubmit,
				fLeft,

				fGoGeneralTxt,
				fSecond
			};
		}
	}
</script>

<style scoped lang="scss">
	.register {
		width: 100%;
		height: 100%;
		background: #F5F5F5;

		.register_body {
			position: relative;

			margin-top: -1.12rem;
			padding: 1.1466rem 0.72rem 0;
			background: #FFFFFF;
			border-radius: 0.8533rem 0.8533rem 0 0;

			.register_form {
				overflow: hidden;
				border-radius: 0.1333rem;
			}


		}

		.register_bottom {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			padding: 0 0.4266rem 0.9066rem;
			background: linear-gradient(180deg, #FFFFFF 0%, #FFF3F3 100%);
		}
	}

	.register_code {
		font-size: 0.3733rem;
		color: #FF7671;
	}
</style>
