<template>
	<div class="selectRole app_flex" :style="isApp ? 'padding-top: 1.3rem;box-sizing: border-box' : ''">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="角色选择" right-text="新增" left-arrow @click-left="fLeft" @click-right="fRight" />
			</van-sticky>
		</div>
		<div class="selectRole_body app_scroll mt12">
			<van-radio-group class="selectRole_radio_group" v-model="oCPData.checkedRoleId" checked-color="#FF7671">
				<van-cell class="selectRole_li" v-for="(item,index) in oCPData.aRoleList" :key="index">
					<template #title>
						<div class="selectRole_li_title">
							<img :src="item.head ? item.head : defaultAvatar" alt="">
							<p class="selectRole_li_shopName">{{item.roleName}}</p>
						</div>
					</template>
					<template #right-icon>
						<van-radio :name="item.roleId" />
					</template>
				</van-cell>
			</van-radio-group>
		</div>
		<van-button class="selectRole_btn app_button" block native-type="submit" :disabled="oCPData.isDisabled"
					@click="fChoose">
			确认
		</van-button>
	</div>
</template>

<script>
	import {
		reactive,
		computed
	} from 'vue';
	import {
		useRoute,
		useRouter,
		onBeforeRouteLeave
	} from 'vue-router';
	import {
		useStore
	} from "vuex";
	import {
		Toast
	} from 'vant';
	import {
		apiQueryUserRole,
		changeDefaultRole,
		// apiUserRole
	} from '@/api/useInfo';
	import {
		uaFun
	} from "@/utils/public.js";
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import defaultAvatar from "@/assets/images/img_032.png";
	export default {
		name: "SelectRole",
		setup() {
			const isApp = uaFun()
			const route = useRoute();
			const router = useRouter();
			const route_query = route.query;
			//离开当前的组件，触发
			onBeforeRouteLeave((to) => {
				if (to.path !== "/addRole" && to.path !== "/redirect" && route_query.redirect && to.path !== ("/" + route_query.redirect)) {
					router.replace({
						name: 'Redirect',
						params: {
							path: route_query.redirect,
							isReset: true
						}
					});
					return false;
				}
			});
			const store = useStore();
			// computed
			const userInfo = computed(() => store.state.userInfo.userInfo);
			const sRouterFrom = store.state.other.sRouterFrom;


			// 角色列表
			const oCPData = reactive({
				aRoleList: [],
				checkedRoleId: null,
				isDisabled: true,
			});
			// 获取角色列表
			const fGetRoleList = () => {
				Toast({
					type: "loading"
				});
				apiQueryUserRole({
					id: userInfo.value.userId
				}).then(res => {
					let data = res.result ? res.result : {};
					if (data && data.length >= 1) {
						oCPData.aRoleList = [...data];

						let roleId = null;
						roleId = oCPData.aRoleList[0];
						oCPData.aRoleList.forEach(roleInfo => {
							if (roleInfo.isDefault === "YES") {
								roleId = roleInfo.roleId;
							}
						})
						oCPData.checkedRoleId = roleId;
						oCPData.isDisabled = false;
					} else {
						oCPData.isDisabled = true;
						// 如果是从新增角色返回来的，则不用继续跳转到新增角色去
						console.log(sRouterFrom);
						store.dispatch("other/USRouterFrom", null)
						if (sRouterFrom !== "/addRole") {
							router.push({
								path: '/addRole'
							})
						}
					}
				}).catch(() => {
					oCPData.isDisabled = true;
				})
			};


			// 提交
			const fChoose = () => {
				oCPData.isDisabled = true;
				let tIsxuanz = true
				Toast({
					type: "loading"
				});
				oCPData.aRoleList.forEach(e=>{
					if(e.isDefault == 'YES'){
						if(oCPData.checkedRoleId == e.roleId){
							tIsxuanz = false
						}

					}
				})
				if(tIsxuanz){
					changeDefaultRole({
						roleId: oCPData.checkedRoleId,
						userId: userInfo.value.userId
					}).then(() => {
						Toast({
							type: "loading"
						});
						return store.dispatch("userInfo/GetUserInfo", userInfo.value.userId);
					}).then(() => {
						oCPData.isDisabled = false;
						const redirect = route_query.redirect ? route_query.redirect : '';
						router.push({
							path: '/' + redirect
						})
					}).catch(() => {
						oCPData.isDisabled = false;
					});
				}else {
					oCPData.isDisabled = false;
					const redirect = route_query.redirect ? route_query.redirect : '';
					router.push({
						path: '/' + redirect
					})
				}

			};
			const fLeft = () => {
				fnIsHasHistory("/loginPw");
			};
			const fRight = () => {
				router.push({
					path: '/addRole'
				})
			};

			fGetRoleList();
			return {
				oCPData,
				fChoose,
				fLeft,
				fRight,
				isApp,
				defaultAvatar
			}
		}
	}
</script>

<style scoped lang="scss">
	.selectRole {
		width: 100%;
		height: 100%;
		background: #FFFFFF;

		.selectRole_body {
			.selectRole_li_title {
				display: flex;
				align-items: center;

				&>img {
					width: 1.0133rem;
					height: 1.0133rem;
					border-radius: 50%;
				}

				&>p {
					margin-left: 0.32rem;
					font-size: 0.3733rem;
					font-weight: 400;
					color: #383838;
					line-height: 0.5333rem;
				}
			}
		}
	}

	.selectRole_img {
		width: 0.5066rem;
	}
</style>
