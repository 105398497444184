<template>
	<div class="app_page login">
		<div class="login_header">
			<div class="login_header_flex">
				<img v-if="config.isShowLoginLogo" src="~@/assets/images/project/yiman_001.png" class="login_img_002">
				<div class="login_header_flex_b">
					<span>你好,</span>
					<span>{{config.title}}欢迎你</span>
				</div>
			</div>
		</div>

		<div class="login_body">
			<van-form class="login_form" @submit="fSubmit">
				<p class="login_label">手机号</p>
				<van-field v-model="oForm.phone" type="tel" name="phone" placeholder="请输入手机号" maxlength="11"
					:rules="[{ validator:rules.phone.validator }]" />
				<p class="login_label">验证码</p>
				<van-field v-model="oForm.code" name="code" placeholder="请输入验证码" maxlength="6"
					:rules="[{ validator:rules.code.validator }]">
					<template #button>
						<span v-if="oCode.isShowBtn" class="login_code" @click="fGetCode">{{oCode.btnText}}</span>
						<span v-else class="login_code">{{fnSecond(current.total)}}s</span>
					</template>
				</van-field>
				<div class="mt65">
					<van-button round block native-type="submit" class="app_button" :disabled="oLoginBtn.isDisabled">
						登录
					</van-button>
				</div>
			</van-form>

			<div class="login_ways">
				<span @click="fLoginOfPw">密码登录</span>
			</div>

			<van-checkbox class="mt16 login_checkbox_text" v-model="oForm.isAgree" checked-color="#FF7671"
				label-disabled>
				我已阅读并同意<span class="app_agreement" @click="fnGoGeneralTxt('user')">《用户协议》</span><span
					class="app_agreement" @click="fnGoGeneralTxt('privacy')">《隐私协议》</span><span
					class="app_agreement" @click="fnGoGeneralTxt('synchronization')">《用户信息同步协议》</span>
			</van-checkbox>
		</div>
	</div>
</template>

<script>
	import {
		reactive,
		inject,
		onActivated,
	} from "vue"
	import {
		// useStore
	} from "vuex"
	//引入路由函数
	import {
		useRoute,
		useRouter
	} from "vue-router"
	import {
		Toast
	} from 'vant'
	import {
		useCountDown
	} from '@vant/use'
	import {
		loginCaptcha,
		captchaLogin
	} from '@/api/useInfo.js'
	import {
		fnSecond,
		fnKeyIsTrue
	} from "@/utils/public.js"
	import {
		fnIsHasHistory,
		fnGoGeneralTxt
	} from "@/utils/router.js"
	import config from "@/utils/config.js"
	import tipsContent from '@/utils/tipsContent'
	export default {
		name: "LoginCode",
		setup() {
			const route = useRoute();
			const router = useRouter();
			const fnGlobalReload = inject("fGlobalReload");
			// const store = useStore(); // 使用useStore方法
			// 规则
			const rules = {
				phone: {
					validator: (val) => {
						console.log(val)
						if (/1\d{10}/.test(val)) {
							oLoginBtn.isTrue.phone = true;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return true
						} else {
							oLoginBtn.isTrue.phone = false;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return false
						}
					}
				},
				code: {
					validator: (val) => {
						if (val.length) {
							oLoginBtn.isTrue.code = true;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return true
						} else {
							oLoginBtn.isTrue.code = false;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return false
						}
					}
				}
			};

			onActivated(() => {
				const route_meta = route.meta;
				if (route_meta.resetType === '1') {
					route_meta.resetType = '0';
					fnGlobalReload();
				} else if (route_meta.resetType === '0') {
					route_meta.resetType = '1';
				}
			});

			// 登录按钮包含属性
			const oLoginBtn = reactive({
				// 所有验证全部通过
				isTrue: {
					phone: false,
					code: false
				},
				// 是否禁用
				isDisabled: true
			});

			//表单字段
			const oForm = reactive({
				phone: null,
				code: null
			});

			// 发送验证码相关对象
			const oCode = reactive({
				isShowBtn: true, //是否展示发送验证码按钮
				btnText: "获取验证码", //发送验证码按钮-文字展示
				isDisabled: false, //是否禁用发送验证码按钮
			});
			// 倒计时
			const countDown = useCountDown({
				// 倒计时 1分钟
				time: 60 * 1000,
				onFinish: () => {
					oCode.btnText = "重新获取";
					oCode.isShowBtn = true;
				}
			});
			// 获取验证码
			const fGetCode = () => {
				console.log("获取验证码");
				// 手机号为空验证
				if (!oForm.phone) {
					return Toast({
						message: tipsContent.emptyPhone,
						position: 'bottom'
					});
				}
				// 手机号规则验证
				if (!oLoginBtn.isTrue.phone) {
					return Toast({
						message: tipsContent.formatPhone,
						position: 'bottom'
					});
				}
				loginCaptcha({
					phone: oForm.phone
				}).then((res) => {
					console.log(res);
					countDown.reset();
					countDown.start();
					oCode.isShowBtn = false;
					oCode.isDisabled = true;
					Toast({
						message: tipsContent.sentMessage,
						position: 'bottom'
					});
				}).catch(() => {
					oCode.isShowBtn = true;
					oCode.isDisabled = false;
				});
			};
			// 表单提交触发事件
			const fSubmit = (values) => {
				console.log(values)
				// 是否同意协议
				if (!oForm.isAgree) {
					return Toast({
						message: tipsContent.disagreeRead + "《用户协议》、《隐私协议》和《用户信息同步协议》哦",
						position: 'bottom'
					});
				}

				Toast({
					type: "loading"
				});
				captchaLogin({
					phone: values.phone,
					smsCaptcha: values.code,
				}).then(res => {
					console.log(res);
					router.push({
						path: "/"
					});
				}).catch(err => {
					console.log(err);
				})
			};

			// 密码登录
			const fLoginOfPw = () => {
				fnIsHasHistory("/loginPw");
			};
			return {
				rules,
				oLoginBtn,
				oForm,
				oCode,

				fSubmit,
				current: countDown.current,
				fGetCode,
				fLoginOfPw,

				fnSecond,
				fnGoGeneralTxt,

				config
			};
		}
	}
</script>

<style scoped lang="scss">
	.login_header {
		width: 100%;
		height: 6.56rem;
		padding: 1.9466rem 0.72rem;
		box-sizing: border-box;
		background-image: url('~@/assets/images/project/img_002.png');
		background-size: 100%;

		.login_header_flex {
			display: flex;
			align-items: center;

			.login_img_002 {
				width: 1.5733rem;
				height: 1.4666rem;
			}

			.login_header_flex_b {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				font-size: 0.6933rem;
				font-weight: 600;
				color: #FFFFFF;
				line-height: 0.8533rem;
			}
		}
	}

	.login_body {
		padding: 1.28rem 0.72rem 0;
		margin-top: -2.2666rem;
		background: #FFFFFF;
		border-radius: 0.4266rem 0.4266rem 0px 0px;
	}

	.login_code {
		font-size: 0.3733rem;
		color: #FF7671;
	}

	.login_ways {
		margin-top: 0.6933rem;
		display: flex;
		align-items: center;
		justify-content: center;

		&>span {
			padding: 0 0.2666rem;
			color: #FF7671;
		}
	}

	.login_checkbox_text {
		padding: 0 0.3466rem;
	}
</style>
