<template>
	<div class="bindIdCard app_flex">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="实名认证" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		
		<div class="bindIdCard_body app_scroll">
			<p class="bindIdCard_tips">请输入真实姓名及身份证号，须与收款账户的开户信息一致！</p>
			
			<van-form submit-on-enter label-width="2.24rem" validate-trigger="onChange" @submit="fSubmit">
				<van-field v-model="oForm.realName" name="realName" label="真实姓名" placeholder="请输入真实姓名" minlength="2" maxlength="20" input-align="right" :border="false"
					:rules="[{ validator:rules.realName.validator }]" />
				<van-field v-model="oForm.idNumber" name="idNumber" label="身份证号" placeholder="请输入身份证号" maxlength="18" input-align="right"
					:border="false" :rules="[{ validator:rules.idNumber.validator }]" />
					
				<div class="bindIdCard_bottom">
					<van-button round block native-type="submit" class="app_button" :disabled="oLoginBtn.isDisabled">
						提交
					</van-button>
				</div>
			</van-form>
		</div>
	</div>
</template>

<script>
	import {
		reactive,
		computed
	} from "vue"
	import {
		useStore
	} from "vuex"
	import {
		useRouter
	} from "vue-router"
	import {
		Toast
	} from 'vant'
	import {
		apiUserNameAuth
	} from '@/api/useInfo.js'
	import {
		fnKeyIsTrue
	} from "@/utils/public.js"
	import {
		validIdCard
	} from '@/utils/validate'
	import tipsContent from '@/utils/tipsContent'
	export default {
		name: "BindIdCard",
		setup() {
			const router = useRouter(); //初始化路由
			const store = useStore(); // 使用useStore方法
			// computed
			const userInfo = computed(() => store.state.userInfo.userInfo);
			
			// 登录按钮包含属性
			const oLoginBtn = reactive({
				// 所有验证全部通过
				isTrue: {
					realName: false,
					idNumber: false,
				},
				// 是否禁用
				isDisabled: true
			});
			// 规则
			const rules = {
				realName: {
					validator: (val) => {
						if (val.length >= 1) {
							oLoginBtn.isTrue.realName = true;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return true
						} else {
							oLoginBtn.isTrue.realName = false;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return false
						}
					}
				},
				idNumber: {
					validator: (val) => {
						if (val.length >= 18) {
							oLoginBtn.isTrue.idNumber = true;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return true
						} else {
							oLoginBtn.isTrue.idNumber = false;
							oLoginBtn.isDisabled = !fnKeyIsTrue(oLoginBtn.isTrue);
							return false
						}
					}
				},
			};
			//表单字段 
			const oForm = reactive({
				realName: '',
				idNumber: '',
			});
			// 表单提交触发事件
			const fSubmit = () => {
				// 密码规则验证
				if (!validIdCard(oForm.idNumber)) {
					Toast({
						message: tipsContent.formatIDnumber,
						position: 'bottom'
					});
					return;
				}
				Toast({
					type: "loading"
				})
				apiUserNameAuth({
					"certNo": oForm.idNumber,
					"realName": oForm.realName,
					"userId": userInfo.value.userId
				}).then(() => {
					return store.dispatch("userInfo/GetUserInfo", userInfo.value.userId)
				}).then(() => {
					router.push({
						path: "/"
					})
				})
			};

			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				router.push({
					path: '/loginPw'
				})
			};


			return {
				rules,
				oLoginBtn,
				oForm,
				fSubmit,
				
				fLeft
			};
		}
	}
</script>

<style scoped lang="scss">
	.bindIdCard {
		width: 100%;
		height: 100%;
		background: #F5F5F5;
		.bindIdCard_body{
			margin: 0 0.32rem;
			.bindIdCard_tips {
				margin: 0.32rem 0 0.4266rem;
				padding: 0.1066rem;
				
				text-align: center;
				font-size: 0.32rem;
				color: #E2335D;
				
				background: rgba(255, 118, 113, 0.15);
				border-radius: 0.3466rem;
				border: 0.0266rem solid #FF7671;
			}
			.bindIdCard_bottom{
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				padding: 0.4266rem;
				background: #FFFFFF;
				border-radius: 0.2133rem 0.2133rem 0px 0px;
			}
		}
	}
</style>
